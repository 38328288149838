import React from 'react';
import styled from "styled-components";

import Electric from '../../images/home/cardlist/electric.png';
import Fire from '../../images/home/cardlist/fire.png';
import Quake from '../../images/home/cardlist/quake.png';
import Thunder from '../../images/home/cardlist/thunder.png';
import Water from '../../images/home/cardlist/water.png';
import { ReactComponent as Roadmap } from '../../images/home/roadmap.svg';

const CardDetail = ({ name, image }) => {
    return (
        <div className="md:w-1/5">
            <div className="flex justify-center">
                <img src={image} alt={name} />
            </div>
        </div>
    )
}

const SpaceCardList = () => {

    const items = [
        {
            'id': 1,
            'name': 'Electric',
            'image': Electric
        },
        {
            'id': 2,
            'name': 'Fire',
            'image': Fire
        },
        {
            'id': 3,
            'name': 'Quake',
            'image': Quake
        },
        {
            'id': 4,
            'name': 'Thunder',
            'image': Thunder
        },
        {
            'id': 5,
            'name': 'Water',
            'image': Water
        }
    ];

    return (
        <StyledDiv className="space-card-list pb-20 justify-center">
            <div className="w-full justify-center text-white pb-20">
                <h2 className="text-[100px] font-bold text-center mb-10">SPACE SIP NFT</h2>
                <p className="text-[20px] text-center">Every NFT Character has its own unique characteristics<br />
                    from outlook to rarity which open different strategies in playing game.</p>
            </div>
            <div className="md:flex w-full justify-center items-center pb-20">
                {items.map((item) => (
                    <CardDetail name={item.name} image={item.image} key={item.id} />
                ))}

            </div>
            <div className="w-full justify-center text-white pt-20 pb-20">
                <p className="text-[20px] text-center">Space Sip is built to allow player PLAY-to-EARN with an EASY-to-PLAY gameplay and still provides an addictive experience.</p>

            </div>
            <div className="w-full justify-center items-center text-white pt-20 pb-20">
                <p className="flex justify-center"><iframe className="lg:w-1/2 sm:w-full md:w-full  aspect-video" src="https://www.youtube.com/embed/OV2Hga2eHq8"></iframe></p>
            </div>
            <div className="w-full justify-center text-white items-center pt-20">
                <h2 className="text-[100px] font-bold text-center">ROAD MAP 2022</h2>
                <div className="flex justify-center stars" id="roadmap">
                    <Roadmap className="object-cover w-full h-full" />
                </div>
            </div>
        </StyledDiv >
    )
}

const StyledDiv = styled.div`
    &.space-card-list{
        
        .stars {
            margin-top: -25rem;
        }
    }
`;

export default SpaceCardList